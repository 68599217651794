<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <div v-if="this.errore_messaggio == ''">
            <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
            <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
          </div>
          <div v-else>
            <h4 class="mt-0 mb-0 text-danger">{{ this.errore_messaggio }}</h4>
          </div>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'area-clienti-dashboard' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna alla Dashboard
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-else>
        <h2 class="mb-2" v-if="tabIniziale == 3">Licenza n. {{ this.dati.licenseData.ref_code }}</h2>
        <h2 class="mb-2" v-else>Accettazione Contratto n. {{ this.dati.licenseData.ref_code }}</h2>
        
        <form-wizard
          ref="myWizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          :hide-buttons="true"
          shape="square"
          :start-index="tabIniziale"
          :prevent-cross-tab="true"
        >
          
          <tab-content
            title="DATI AZIENDALI"
          >

            <div v-if="this.campiform.dati_cliente.billing">
              <h4 class="mb-0">{{ this.campiform.dati_cliente.billing.company_name }}</h4>
              <p>
                {{ this.campiform.dati_cliente.billing.address }} - {{ this.campiform.dati_cliente.billing.zip }} - {{ this.campiform.dati_cliente.billing.city }} 
                <span v-if="this.campiform.dati_cliente.billing.province_id == 112">({{ this.campiform.dati_cliente.billing.province_foreign }})</span>
                <span v-else>({{ this.campiform.dati_cliente.billing.province_name }})</span>
                 - {{ this.campiform.dati_cliente.billing.country_name }}
              </p>
              <p>
                <span class="text-muted">P.Iva:</span> {{ this.campiform.dati_cliente.billing.vat_number }}<br />
                <span class="text-muted">Cod. fiscale:</span> {{ this.campiform.dati_cliente.billing.fiscal_code }}<br />
                <span class="text-muted">Pec:</span> {{ this.campiform.dati_cliente.billing.pec }}<br />
                <span class="text-muted">SDI:</span> {{ this.campiform.dati_cliente.billing.dest_code }}
              </p>

              <p>
                <span class="text-muted">Rappresetnate legale:</span> {{ this.campiform.dati_cliente.billing.legal_representative }}
              </p>

              <p class="mb-3">
                <span class="text-muted">Riferente:</span> {{ this.dati.licenseData.user.surname }} {{ this.dati.licenseData.user.name }}<br />
                <span class="text-muted">Email:</span> {{ this.dati.licenseData.user.email }}<br />
                <span class="text-muted">Cellulare:</span> {{ this.dati.licenseData.user.mobile_phone }}<br />
                <span class="text-muted">Telefono:</span> {{ this.dati.licenseData.user.phone }}
              </p>
            </div>

            <div class="mb-2">
              <b-button variant="outline-primary" class="text-uppercase" @click="confermaDati">Conferma dati e prosegui</b-button>
              <b-button variant="outline-secondary" class="text-uppercase ml-3" @click="aggiornaDati(campiform.dati_cliente.billing.address, campiform.dati_cliente.billing.zip, campiform.dati_cliente.billing.city, campiform.dati_cliente.billing.province_id, campiform.dati_cliente.billing.province_foreign, campiform.dati_cliente.billing.country_id, campiform.dati_cliente.billing.vat_number, campiform.dati_cliente.billing.fiscal_code, campiform.dati_cliente.billing.pec, campiform.dati_cliente.billing.dest_code, campiform.dati_cliente.billing.legal_representative)">Modifica dati</b-button>
            </div>
          </tab-content>

          <tab-content
            title="CONTRATTO"
          >
            <div class="text-center my-3" v-if="Caricato_testi_contratto == false">
                <b-spinner
                    variant="primary"
                    style="width: 3rem; height: 3rem;"
                    class="mr-1"
                    label="loading"
                />
            </div>
            <div v-else>
              <div v-if="errore_testi_contratto">

              </div>
              <div v-else>
                <validation-observer ref="simpleRules">
                  <b-form>
                    <h4>Modulo d'ordine</h4>
                      <div class="box_testi_contratto mb-1" v-html="this.campiform.dati_contratto.modulo_ordine"></div>

                      <b-form-group label-for="modulo_ordine" class="mb-3">
                        <validation-provider
                          #default="{ errors }"
                          name="Accettazione obbligatoria del Modulo d'ordine"
                          rules="required"
                          >
                          <b-form-checkbox
                            v-model="campiform.dati_contratto.accettazione_modulo_ordine"
                            value="ok"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                          >
                            Accettazione Esplicita Modulo d'Ordine
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    <h4>Termini e Condizioni</h4>
                      <div class="box_testi_contratto mb-1" v-html="this.campiform.dati_contratto.termini_e_condizioni"></div>

                      <b-form-group label-for="termini_e_condizioni" class="mb-0">
                        <validation-provider
                          #default="{ errors }"
                          name="Accettazione obbligatoria dei Termini e Condizioni"
                          rules="required"
                          >
                          <b-form-checkbox
                            v-model="campiform.dati_contratto.accettazione_termini_e_condizioni"
                            value="ok"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                          >
                          Accettazione Esplicita Termini e Condizioni
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group label-for="accettazione_modalita_pagamento" class="mb-3">
                        <validation-provider
                          #default="{ errors }"
                          name="Accettazione obbligatoria delle Modalita' di pagamento"
                          rules="required"
                          >
                          <b-form-checkbox
                            v-model="campiform.dati_contratto.accettazione_modalita_pagamento"
                            value="ok"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                          >
                          Accettazione Esplicita Modalità di Pagamento Art. 5
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    <h4>Clausole ai sensi Art. 1341 e 1342 C.C. accettate singolarmente</h4>
                      <div class="box_testi_contratto mb-1" v-html="this.campiform.dati_contratto.clausole_vessatorie"></div>

                      <b-form-group label-for="clausole_vessatorie" class="mb-3">
                        <validation-provider
                          #default="{ errors }"
                          name="Accettazione obbligatoria delle Clausole Art. 1341 e 1342"
                          rules="required"
                          >
                          <b-form-checkbox
                            v-model="campiform.dati_contratto.accettazione_clausole_vessatorie"
                            value="ok"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                          >
                          Accettazione Esplicita Clausole Art. 1341 e 1342 per ogni punto singolarmente
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    <h4>Privacy Policy</h4>
                      <div class="box_testi_contratto mb-1" v-html="this.campiform.dati_contratto.privacy_policy"></div>

                      <b-form-group label-for="privacy_policy" class="mb-3">
                        <validation-provider
                          #default="{ errors }"
                          name="Accettazione obbligatoria della Privacy Policy"
                          rules="required"
                          >
                          <b-form-checkbox
                            v-model="campiform.dati_contratto.accettazione_privacy_policy"
                            value="ok"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                          >
                          Accettazione Esplicita Privacy Policy
                          </b-form-checkbox>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    <b-row>
                      <b-col cols="12" md="3" lg="2">
                        Inserisci Codice OTP <b-button v-b-tooltip.hover title="Questo codice viene generato ogni giorno, se non lo hai aggiornato richiedi il codice OTP"
                        variant="flat-info" cl v-ripple.400="'rgba(0, 207, 232, 0.15)'" class="btn-icon"><feather-icon icon="InfoIcon" /></b-button> 
                      </b-col>

                      <b-col cols="12" md="4" xl="3">
                        <b-form-group label-for="codice_otp">
                          <validation-provider
                            #default="{ errors }"
                            name="Codice OTP"
                            rules="required"
                            >
                            <b-form-input
                                v-model="campiform.codice_otp"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="5" xl="7">
                        <b-button
                            variant="outline-primary" 
                            class="mb-3" 
                            type="submit"
                            :disabled="action_press_otp"
                            @click.prevent="inviaCodiceOTP(dati.licenseData.user.id)"
                        >
                            <div v-if="action_press_otp">Invio in corso <b-spinner small class="ml-1" /></div>
                            <div v-else>Invia codice OTP</div> 
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-button
                        variant="outline-primary" 
                        class="text-uppercase mb-3" 
                        type="submit"
                        :disabled="action_press"
                        @click.prevent="validationFormAccettazione()"
                    >
                        <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                        <div v-else>Firma</div> 
                    </b-button>
                  </b-form>
                </validation-observer>

              </div>
            </div>
          </tab-content>

          
          <tab-content title="PAGAMENTO"></tab-content>


          <tab-content
            title="RIEPILOGO"
          >
            <div v-if="stato_licenza == 'gia_pagato'">
              <p>Grazie per aver scelto AgentScout,<br />di seguito puoi scaricare il contratto</p>
              <p v-if="btn_scaricare">
                <a :href="link_download_pdf" target="_blank" class="btn btn-primary mt-1 mb-2">Scarica plico contratto</a>
              </p>
            </div>

            <div v-else-if="stato_licenza == 'sospesa'">
              <p>Gentile Cliente,<br />la sua licenza risulta essere sospesa.</p>
              <p>La invitiamo a contattare i nostri uffici per ulteriori delucidazioni e/o assistenza.</p>
            </div>

            <div v-else-if="stato_licenza == 'scaduta'">
              <p>Gentile Cliente,<br />la sua licenza risulta essere scaduta.</p>
              <p>Se desidera riattivare la sua licenza, La invitiamo a contattare i nostri uffici per ricevere assistenza a riguardo.</p>
            </div>

            <div v-else-if="stato_licenza == 'revocata'">
              <p>Gentile Cliente,<br />la sua licenza risulta essere revocata.</p>
              <p>La invitiamo a contattare i nostri uffici per ulteriori delucidazioni e/o assistenza.</p>
            </div>

            <div v-else>
              <p>Gentile Cliente,<br />la sua licenza risulta essere in recesso.</p>
              <p>La invitiamo a contattare i nostri uffici per ulteriori delucidazioni e/o assistenza.</p>
            </div>

          </tab-content>
      
        </form-wizard>


      <b-modal
        ref="aggiornaDati-modal"
        size="lg"
        centered
        hide-footer
        title="Aggiorna Dati Societari"
        @hide="resetaggiornaDatimodal"
      >
        <div class="text-center my-3" v-if="Caricato_aggiornaDati == false">
            <b-spinner  v-if="errore_caricamento == false"
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
            />
            <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-if="Caricato_aggiornaDati">
          <validation-observer ref="aggiornaRules">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Indirizzo *" label-for="address">
                    <validation-provider
                    #default="{ errors }"
                    name="Indirizzo"
                    rules="required|min:5"
                    >
                      <b-form-input
                          v-model="aggiornaDatiModal.address"
                          placeholder=""
                          :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Cap *" label-for="zip">
                    <validation-provider
                    #default="{ errors }"
                    name="Cap"
                    :rules="lunghezza_campo_cap"
                    >
                    <b-form-input
                        v-model="aggiornaDatiModal.zip"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Città *" label-for="city">
                    <validation-provider
                    #default="{ errors }"
                    name="Città"
                    rules="required|min:2"
                    >
                      <b-form-input
                          v-model="aggiornaDatiModal.city"
                          placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <div v-if="aggiornaDatiModal.country_id != 114">
                    <b-form-group label="Provincia *" label-for="province_foreign">
                      <b-form-input
                          v-model="aggiornaDatiModal.province_foreign"
                          placeholder=""
                          rules="min:2"
                      />
                    </b-form-group>
                  </div>
                  <div v-else>
                    <b-form-group label="Provincia *" label-for="province_id">
                      <b-form-select
                          v-model="aggiornaDatiModal.province_id"
                          :options="options_province"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Nazione *" label-for="country_id">
                    <vue-autosuggest
                      ref="autocomplete"
                      v-model="valoreDefaultNazione"
                      :suggestions="filteredOptions"
                      :limit="8"
                      :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'cerca nazione', }"
                      :render-suggestion="renderSuggestion"
                      :get-suggestion-value="getSuggestionValue"
                      @input="onInputChange"
                      @selected="selectHandler"
                    >
                      <template slot-scope="{suggestion}">
                        <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                      </template>
                    </vue-autosuggest>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Partita IVA *" label-for="vat_number">
                    <validation-provider
                    #default="{ errors }"
                    name="Partita IVA"
                    rules="required|regex:^([a-zA-Z0-9]+)$|min:5"
                    >
                      <b-form-input
                        v-model="aggiornaDatiModal.vat_number"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Codice fiscale *" label-for="fiscal_code">
                    <validation-provider
                    #default="{ errors }"
                    name="Codice fiscale"
                    rules="required|min:7"
                    >
                      <b-form-input
                        v-model="aggiornaDatiModal.fiscal_code"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Pec" label-for="pec">
                    <validation-provider
                    #default="{ errors }"
                    name="Pec"
                    rules="required|email"
                    >
                      <b-form-input
                        v-model="aggiornaDatiModal.pec"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Codice SDI *" label-for="dest_code">
                    <validation-provider
                    #default="{ errors }"
                    name="Codice SDI"
                    rules="required|length:7"
                    >
                      <b-form-input
                        v-model="aggiornaDatiModal.dest_code"
                        :state="errors.length > 0 ? false:null"
                        placeholder=""
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Rappresentante legale *" label-for="legal_representative">
                    <validation-provider
                    #default="{ errors }"
                    name="Rappresentante legale"
                    rules="required|min:4"
                    >
                      <b-form-input
                          v-model="aggiornaDatiModal.legal_representative"
                          placeholder=""
                          :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12" class="text-center">
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="action_press_aggiorna_dati_azienda"
                      @click.prevent="aggiornaDatiAziendaForm()"
                    >
                      <div v-if="action_press_aggiorna_dati_azienda">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                      <div v-else>Salva</div> 
                    </b-button>
                </b-col>

              </b-row>
            </b-form>
          </validation-observer> 
        </div>
      </b-modal>

    </div>

      
  </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BSidebar, VBToggle, BBadge, BForm, BFormGroup, BFormCheckbox, BFormInput, BFormSelect, BModal, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver, localize, configure  } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import {
  required, length,
} from '@validations'
import moment from 'moment'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,
    BForm, 
    BFormGroup, 
    BFormCheckbox, 
    BFormInput, 
    BFormSelect, 
    BModal, 
    VBTooltip, 

    showdata,

    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,

    ValidationProvider,
    ValidationObserver,
    it,

    VueAutosuggest, 
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      Caricato: false,
      errore_caricamento: false,
      id_licenza: router.currentRoute.params.id_riga,
      dati: [],
      stato_licenza: 'da_accettare',
      errore_messaggio: '',

      Caricato_testi_contratto: false,
      errore_testi_contratto: false,

      isSubscription: false,

      tabIniziale: 0,
      
      required,
      length,

      campiform: {
        id_licenza: router.currentRoute.params.id_riga,
        id_user: '',
        ip_address: '',
        user_agent: '',
        conferma_dati: false,
        dati_cliente: {},
        dati_contratto: {},
      },

      Caricato_aggiornaDati: false,
      errore_caricamento_aggiornaDati: false,
      
      lunghezza_campo_cap: 'required|digits:5', 
      id_italina: '114',
      valoreDefaultNazione: "",
      filteredOptions: [],
      options: [ ],
      options_province: [ ],

      aggiornaDatiModal: {
        id_utente: '',
        address: '',
        zip: '',
        city: '',
        province_id: '',
        province_foreign: '',
        country_id: '',
        vat_number: '',
        fiscal_code: '',
        pec: '',
        dest_code: '',
        legal_representative: '',
      },
      
      action_press_aggiorna_dati_azienda: false,
      action_press_otp: false,
      action_press: false,
      action_press_donwload_plico: false,

      btn_verifica: true,
      btn_scaricare: false,
      link_download_pdf: '',
    }
  },
  created() {
    //user agent del cliente
    //console.log(navigator.userAgent)
    this.campiform.user_agent = navigator.userAgent;

    this.$http.get('api/customer/license/card/'+this.id_licenza).then(response => { 
        /*  
        console.log(response)
        console.log("stato esterno -> "+response.status)
        console.log("stato interno -> "+response.data.statusCode)
        */

        if(response.status===200){
          //ok dati ricevuti
          // check autorizzazione ad accedere ai dati oppure no
          if(response.data.statusCode===200){
            //ok autorizzato ! => proseguo
            this.dati = response.data.reply

            console.log("FINE --------------------")
            console.log(this.dati)

            //FONDAMENTALE PER STRIPE !!
            if(this.dati.licenseData.type == 'Abbonamento'){
              this.isSubscription = true;
            } else {
              this.isSubscription = false; //OneShot
            }

            //inserimento user_id nel dati da passare per salvataggio del contratto
            this.campiform.id_user = this.dati.licenseData.user.id;

            if(this.dati.licenseData.contract === null){
              //non è stato ancora accettato il contratto (manca proprio i dati nel DB) - nenache fatto un primo tentativo da parte del cliente
              // è la primva volta che il cliente accedere al wizzard per l'accettazione del contratto
              this.stato_licenza = 'da_accettare'

              this.estrazioneDatiBilling(this.dati.licenseData.user.id)

            } else {
              if(this.dati.licenseData.contract.accepted_at === null){
                //licenza da accettare da parte del cliente
                this.stato_licenza = 'da_accettare'

                this.estrazioneDatiBilling(this.dati.licenseData.user.id)

              } else {
                if(this.dati.licenseData.status.id == '25'){
                  //licenza già accettata => rimando alla nuova pagina per effettuato il pagamento integrato
                  this.$router.replace('/area-clienti/licenze/pay/'+this.id_licenza)


                } else if(this.dati.licenseData.status.id == '30'){
                  //Licenza da ATTIVA (id = 30) -> Pagata
                  this.stato_licenza = 'gia_pagato'

                  /* forzo il passaggio al quarto step => RIEPILOGO */
                  this.tabIniziale = 3;

                  /* recupero link per scaricare contratto plcio pdf in modo sicuro */
                  this.scaricaPDFplico()

                  this.Caricato = true;

                } else if(this.dati.licenseData.status.id == '40'){
                  //Licenza da ATTIVA (id = 40) -> Sospesa
                  this.stato_licenza = 'sospesa'

                  /* forzo il passaggio al quarto step => RIEPILOGO */
                  this.tabIniziale = 3;

                  this.Caricato = true;

                } else if(this.dati.licenseData.status.id == '50'){
                  //Licenza da ATTIVA (id = 50) -> Scaduta
                  this.stato_licenza = 'scaduta'

                  /* forzo il passaggio al quarto step => RIEPILOGO */
                  this.tabIniziale = 3;

                  this.Caricato = true;

                } else if(this.dati.licenseData.status.id == '60'){
                  //Licenza da ATTIVA (id = 50) -> Revocata
                  this.stato_licenza = 'revocata'

                  /* forzo il passaggio al quarto step => RIEPILOGO */
                  this.tabIniziale = 3;

                  this.Caricato = true;

                } else {
                  //Licenza da ATTIVA (id = 50) -> In recesso
                  this.stato_licenza = 'in_recesso'

                  /* forzo il passaggio al quarto step => RIEPILOGO */
                  this.tabIniziale = 3;

                  this.Caricato = true;
                }
              }
            }
            
          } else {
            if(response.data.statusCode===403){
              //accesso ad una licenza che non è del cliente loggato, furbetto beccato!
              this.errore_messaggio = "Operazione non autorizzata! stai cercando di accedere ad una licenza non di tua pertinenza."
            }
            
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          console.log("qui ------------------")
          if(response.status===401){
            //accesso "Unauthenticated" -> accade quando si sviene sloggati dall'api
            console.error("Errore api -> "+response.data.reply['message'])

            if(logoutGeneral() === true){
              // Redirect to login page
              //this.$router.push({ name: 'auth-login' })
              this.$router.replace('/login?status=again')
            }
          }

          this.Caricato = false;
          this.errore_caricamento = true;
        }
      
      }).catch(e => {
        /*
        this.Caricato = false;
        this.errore_caricamento = true;
        */

        console.log("Errore api ......")
        console.log(e)

        this.Caricato = false;
        this.errore_caricamento = true;

      });

    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })
    // Imposta la lingua predefinita
    localize('it');

    //rileva IP connessione utente
    this.rilevaIP();
  },
  methods: {
    estrazioneDatiBilling(id_user){

      const countryPromise = this.$http.get('api/fs/country/list')
      const provincePromise = this.$http.get('api/fs/province/list')
      const infoBillingPromise = this.$http.get('/api/customer/user/customerbillingcard/'+id_user)

      Promise.all([countryPromise, provincePromise, infoBillingPromise]).then(response => {
        if((response[0].data.statusCode===200) && (response[1].data.statusCode===200) && (response[2].data.statusCode===200)){
          /*
          console.log(response[0])
          console.log(response[1])
          console.log(response[2])
          */

          this.campiform.dati_cliente = response[2].data.reply

          //nazione
          var id_nazione = this.campiform.dati_cliente.billing.country_id
          var nazione = response[0].data.reply.filter(function(elem){
              if(elem.value == id_nazione){
              return elem.text
              }
          })
          this.campiform.dati_cliente.billing.country_name = nazione[0].text

          //provincia
          var id_provincia = this.campiform.dati_cliente.billing.province_id
          var provincia = response[1].data.reply.filter(function(elem){
              if(elem.value == id_provincia){
                return elem.text
              }
          })
          this.campiform.dati_cliente.billing.province_name = provincia[0].text

          //fine loader
          this.Caricato = true;
          
        } else {
          console.log("Errore api ...... estrazioneDatiBilling()")
        }        
      })

      /*
      this.$http.get('/api/customer/user/customerbillingcard/'+id_user).then(response => { 
        console.log(response)

        this.campiform.dati_cliente = response.data.reply
        
        //fine loader
        this.Caricato = true;
      
      }).catch(e => {
        console.log("Errore api ...... estrazioneDatiBilling()")
        console.log(e);
      });
      */
    },
    rilevaIP(){
      fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
        //console.log("Ip rilevato -> "+ip);
        this.campiform.ip_address = ip;
        //console.log("Ip rilevato dopo -> "+this.campiform.ip_address);
      });
    },
    aggiornaDati(address, zip, city, province_id, province_foreign, country_id, vat_number, fiscal_code, pec, dest_code, legal_representative){
      
      this.aggiornaDatiModal = {
        id_utente: this.campiform.id_user,
        address: address,
        zip: zip,
        city: city,
        province_id: province_id, 
        province_foreign: province_foreign, 
        country_id: country_id, 
        vat_number: vat_number,
        fiscal_code: fiscal_code,
        pec: pec,
        dest_code: dest_code,
        legal_representative: legal_representative,
      }

      console.log(this.aggiornaDatiModal)

      //aperi modale
      this.$refs['aggiornaDati-modal'].show()

      //inizia caricamento dati geoghrafici per i select
      const countryPromise = this.$http.get('api/fs/country/list')
      const provincePromise = this.$http.get('api/fs/province/list')

      Promise.all([countryPromise, provincePromise]).then(response => {
        if(response[0].data.statusCode===200){
          //caricamento lista nazioni
          this.options = response[0].data.reply

          if(response[1].data.statusCode===200){
            //caricamento lista province italiane
            this.options_province = response[1].data.reply

            //valore default per campo Nazione con autosuggest
            var id_nazione = this.aggiornaDatiModal.country_id
            var nazione = this.options.filter(function(elem){
                if(elem.value == id_nazione){
                return elem.text
                }
            })
            this.valoreDefaultNazione = nazione[0].text
              
            this.Caricato_aggiornaDati = true;
            this.errore_caricamento_aggiornaDati = false;
              
          } else {
            this.Caricato_aggiornaDati = false;
            this.errore_caricamento_aggiornaDati = true;
          }
        } else {
          this.Caricato_aggiornaDati = false;
          this.errore_caricamento_aggiornaDati = true;
        }
      })

    },
    onInputChange(testo_ricerca) {
      if (testo_ricerca === '' || testo_ricerca === undefined) {
        return
      }

      //filtra array nazioni
      const filteredData = this.options.filter(item => item.text.toLowerCase().indexOf(testo_ricerca.toLowerCase()) == 0).slice(0, this.limit)
      
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    renderSuggestion(suggestion) {
      //per gestire le voci restituite nella finestra auto-suggest
      return suggestion.item.text;
    },
    getSuggestionValue(suggestion) {
      //per gestire il testo che verra visualizzato dopo la selezione dela voce dalla finestra dell'auto-suggest
      return suggestion.item.text;
    },
    selectHandler(text){
      console.log("selectHandler -> "+text.item.value)
      if (text === '' || text === undefined) {
        return
      } else {
        //associo id_nazione al parametro da passare all'api
        this.aggiornaDatiModal.country_id = text.item.value

        if(this.aggiornaDatiModal.country_id == this.id_italina){
          //forzo campo "province_id"
          this.aggiornaDatiModal.province_id = '111';
          //lunghezza CAP (per validazione)
          this.lunghezza_campo_cap = 'required|digits:5';
        } else {
          //province_id estera
          this.aggiornaDatiModal.province_id = '112';
          //lunghezza CAP (per validazione)
          this.lunghezza_campo_cap = 'required|min:2';
        }
      }
    },
    aggiornaDatiAziendaForm() {
      this.$refs.aggiornaRules.validate().then(success => {
        if (success) {
          console.log('aggiornaDatiAziendaForm ------')
          console.log(this.aggiornaDatiModal)

          this.action_press_aggiorna_dati_azienda = true
          
          this.$http.post('/api/customer/user/updatedati', 
            this.aggiornaDatiModal
          ).then(response => { 
            console.log(response.data)

            if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
              //sincronizzazione dati su Fatture in Cloud
              this.$http.post('api/customer/user/sync_fic/'+response.data.reply.customer_id)
                .then(response_sync => {
                  if((response_sync.data.statusCode>=200) && (response_sync.data.statusCode<=299)){
                    //risposta positiva

                    //forzo aggiornamento dati visualizzati senza richiamare api
                    this.campiform.dati_cliente.billing.address = response.data.reply.data_update.address
                    this.campiform.dati_cliente.billing.zip = response.data.reply.data_update.zip
                    this.campiform.dati_cliente.billing.city = response.data.reply.data_update.city
                    this.campiform.dati_cliente.billing.vat_number = response.data.reply.data_update.vat_number
                    this.campiform.dati_cliente.billing.fiscal_code = response.data.reply.data_update.fiscal_code
                    this.campiform.dati_cliente.billing.pec = response.data.reply.data_update.pec
                    this.campiform.dati_cliente.billing.dest_code = response.data.reply.data_update.dest_code
                    this.campiform.dati_cliente.billing.province_id = response.data.reply.data_update.province_id
                    this.campiform.dati_cliente.billing.country_id = response.data.reply.data_update.country_id
                    this.campiform.dati_cliente.billing.province_foreign = response.data.reply.data_update.province_foreign
                    
                    //chiudi finestra
                    this.$refs['aggiornaDati-modal'].hide();

                    this.$swal({
                      icon: 'success',
                      title: response.data.reply.risposta,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                    })

                  } else {
                    //risposta negativa (errore sul server)
                    this.$swal({
                        icon: 'error',
                        title: 'Si è verificato un errore nella sincronizzazione dai dati',
                        text: 'Operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema: codice errore FIC-CUS-03',
                        customClass: {
                        confirmButton: 'btn btn-outline-primary',
                        },
                    })
                      
                    this.action_press = false
                  }
                }).catch(e => {
                  console.log(e);
                });

            } else {
              if(response.data.statusCode == 422){
                //errore di validazione delle informazioni
                this.$swal({
                  icon: 'warning',
                  title: 'Attenzione',
                  text: response.data.reply.message,
                  customClass: {
                    confirmButton: 'btn btn-outline-primary',
                  },
                })
              } else {
                //qualsiasi altro tipo di errore
                this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore generico',
                  text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema - codice errore CCL 7',
                  customClass: {
                    confirmButton: 'btn btn-outline-primary',
                  },
                })
              }
            }

            //fine loader
            this.action_press_aggiorna_dati_azienda = false
          
          }).catch(e => {
            console.log("Errore api ...... confermaDati()")
            console.log(e);

            //fine loader
            this.action_press_aggiorna_dati_azienda = false
          });
        }
      })
    },
    resetaggiornaDatimodal(){
      this.aggiornaDatiModal = {
        address: '',
        zip: '',
        city: '',
        vat_number: '',
        fiscal_code: '',
        pec: '',
        dest_code: '',
        legal_representative: '',
      }
    },
    confermaDati() {
      this.campiform.conferma_dati = true; //per contrassegnare come accettato i dati iniziali

      //estrazione testi per il contratto in base al tipo di licenza (product_id_stripe)
      this.$http.post('/api/customer/contract/template', {
        id_prod_stripe: this.dati.licenseData.product_id_stripe,
      }).then(response => { 
        console.log(response)

        this.campiform.dati_contratto = response.data.reply

        //fine loader
        this.Caricato_testi_contratto = true;
        this.errore_testi_contratto = false;
      
      }).catch(e => {
        console.log("Errore api ...... confermaDati()")
        console.log(e);

        //fine loader
        this.Caricato_testi_contratto = true;
        this.errore_testi_contratto = true;
      });

      
      this.$refs.myWizard.changeTab(0,1); //passo allo step successivo (CONTRATTO)
    },

    inviaCodiceOTP(id_user){
      console.log("siamo in inviaCodiceOTP ----------------")

      this.action_press_otp = true;

      this.$http.get('/api/customer/user/inviootp/'+id_user).then(response => { 
        //console.log(response)

        if(response.data.reply.data.original.risposta){
          this.$swal({
              icon: 'success',
              title: response.data.reply.data.original.risposta,
              confirmButtonText: 'chiudi',
              customClass: {
              confirmButton: 'btn btn-success',
              },
          })
        } else {
          this.$swal({
              icon: 'danger',
              title: 'Impossibile inviare il codice OTP - prego contattare l\'assistenza di Agentscout - codice errore OTP-51',
              confirmButtonText: 'chiudi',
              customClass: {
              confirmButton: 'btn btn-success',
              },
          })
        }
        
        //fine loader
        this.action_press_otp = false;
      
      }).catch(e => {
        console.log("Errore api ...... inviaCodiceOTP()")
        console.log(e);
      });
    },

    validationFormAccettazione() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //console.log(this.campiform)
          this.action_press = true

          this.$http.post('/api/customer/contract/acceptandsave', 
            this.campiform
          ).then(response => { 
            console.log(response.data)

            if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
              //risposta positiva
              if(response.data.reply.mess_errore != ''){
                this.$swal({
                  icon: 'warning',
                  title: 'Attenzione',
                  text: response.data.reply.mess_errore,
                  customClass: {
                    confirmButton: 'btn btn-outline-primary',
                  },
                })
              } else {
                //ok è andata a buone fine il salvataggio
                //passo allo step successio (PAGAMENTO) -> file separato "pay"
                this.$router.replace('/area-clienti/licenze/pay/'+this.id_licenza)

              }

              //rimuovi loader
              this.action_press = false

            } else {
              if(response.data.statusCode == 422){
                //errore di validazione delle informazioni
                this.$swal({
                  icon: 'warning',
                  title: 'Attenzione',
                  text: response.data.reply.message,
                  customClass: {
                    confirmButton: 'btn btn-outline-primary',
                  },
                })
              } else {
                //qualsiasi altro tipo di errore
                this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore generico',
                  text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema - codice errore CCL 19',
                  customClass: {
                    confirmButton: 'btn btn-outline-primary',
                  },
                })
              }

              //rimuovi loader
              this.action_press = false
            }
          
          }).catch(e => {
            if(prezzo == 0){

            } else {
              //così rimane il pulsante di "accettazione"
              this.stato_licenza = 'da_accettare',

              this.Caricato = true;
              console.log(e);
            }
          });
          
          
        }
      })
    },

    AccettaContratto_OLD_FUNZIONE_RIMOSSA(id_contratto, prezzo, ip_connessione_cliente){
      this.Caricato = false;

      console.log("id_contratto -> "+id_contratto+"\nip -> "+ip_connessione_cliente)

      this.$http.post('/api/customer/contract/accept/'+id_contratto, {
        user_agent: navigator.userAgent,
        ip_address: ip_connessione_cliente
      }).then(response => { 
        console.log(response)

        if(prezzo === 0){
          //licenza gratuita
          this.stato_licenza = 'omaggio_auto_attivato',
          this.Caricato = true;
        } else {
          this.stato_licenza = 'accettato',
          this.Caricato = true;
        }
      
      }).catch(e => {
        if(prezzo === 0){

        } else {
          //così rimane il pulsante di "accettazione"
          this.stato_licenza = 'da_accettare',

          this.Caricato = true;
          console.log(e);
        }
      });      
    },
    PagaContratto(){
      this.Caricato = false;

      this.$http.post('/api/customer/stripe/checkout/'+this.id_licenza).then(response => { 
        console.log(response)
        console.log("redirect ->\n\n"+response.data.reply.checkout_url)

        // OLD VERSION
        // redirect verso stripe
        window.location.href = response.data.reply.checkout_url;
        
        //NON RIMUOVO MAI IL LOADER COSì NON VEDO LO SFARFALLIO DELLA PAGINA ;-)
        //this.Caricato = true;
      
      }).catch(e => {
        this.Caricato = true;
        console.log(e);
      }); 

    },

    scaricaPDFplico(){
      this.action_press_donwload_plico = true;

      this.$http.post('/api/customer/license/genera-codice-plico/'+this.id_licenza).then(response => { 
        //console.log(response)

        if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
          //console.log(response.data.reply.dominio+'/download-contratto/pdf/'+response.data.reply.codice);

          //redirect verso nuova finestra per il download
          //window.location.href = response.data.reply.dominio+'/download-contratto/pdf/'+response.data.reply.codice;

          //apro nuova tab per scaricare direttamente il file pdf (ma ha il problema del blocco finestra popup)
          //window.open(response.data.reply.dominio+'/download-contratto/pdf/'+response.data.reply.codice, '_blank');

          //gestione del download via click per evitare qualsiasi problema di apertura di finestre per il download
          this.link_download_pdf = response.data.reply.dominio+'/download-contratto/pdf/'+response.data.reply.codice;
          this.btn_verifica = false;
          this.action_press_donwload_plico = false;
          this.btn_scaricare = true;

        } else {
          this.action_press_donwload_plico = false;

          //qualsiasi altro tipo di errore
          this.$swal({
            icon: 'error',
            title: 'Si è verificato un errore',
            text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema - codice errore DWN 4',
            customClass: {
              confirmButton: 'btn btn-outline-primary',
            },
          })
        }

      }).catch(e => {
        this.action_press_donwload_plico = false;
        console.log(e);
      });
    },

    formattazioneOrario(value, stringa){
      return moment(String(value)).locale('it').format(stringa)
    },
    
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.wizard-header{
  display: none;
}

/* disabilità il ckick sui titoli del wizzard */
.vue-form-wizard .wizard-nav-pills a, .vue-form-wizard .wizard-nav-pills li{
  cursor: not-allowed;
  pointer-events: none;
}

.box_testi_contratto{
  width: 100%;
  max-height: 90px;
  overflow-y: visible;
  overflow-x: hidden;
  background-color: rgba(34, 41, 47, 0.03);
  border: 1px solid rgba(34, 41, 47, 0.08);
  padding: 6px 10px;
  font-size: 11px;
}
.box_testi_contratto p{
  line-height: 13px;
  padding-bottom: 0px;
  margin-bottom: 9px;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #009688 !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .active {
  color: #009688 !important;
}
</style>
